.offer__info {
  width: 100%;
  padding: 100px;
  background-color: #ccc;
  display: grid;
  place-items: center;
}

.offer__name {
  text-transform: capitalize;
}

.offered_by {
  padding: 1rem;
  text-align: center;
  text-transform: capitalize;
}

.offer_nav {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 600;
  padding: 10px;
  background-color: rgb(255, 255, 255);
}

.offered_by span {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .offer_carousel {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .offer__info {
    padding: 75px;
  }

  .offer_carousel {
    height: 250px !important;
  }
}

@media screen and (max-width: 500px) {
  .offer__info {
    padding: 50px;
  }
}
