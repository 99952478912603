.not-found-container {
    text-align: center;
    margin: 50px;
  }
  
  .not-found-title {
    font-size: 36px;
    color: #333;
  }
  
  .not-found-message {
    font-size: 18px;
    color: #666;
  }

  .not-found-link {
    text-decoration: underline;
    font-weight: 700;
    color: #333;
  }
  