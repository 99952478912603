@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

.vendor {
  width: 100%;
  height: auto;
  /* min-height: 100vh; */
  font-family: "Jost", sans-serif;
  color: 333333;
}

.vendorTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.vendorTitleContainer {
  width: 100%;
  background: #ccc;

}

.vendorTitle {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 2rem 0;
  text-align: center;
}

.vendorTitle span {
  font-size: 1rem;
}


.vendorInfo {
  display: flex;
}

.contactInfo,
.openingHours {
  width: 50%;
  flex: 1;
  padding: 1rem;
}

.vendorSubtitle {
  font-size: 1.4rem;
  font-weight: 800;
}

.vacation {
  padding-top: 20px;
}

.contactRow {
  padding: 5px 0;
  display: flex;
  gap: 10px;
}

.vendorHoursRow {
  margin-bottom: 0.4rem;
  border-bottom: 1px solid #ccc;
}

.storeStatus {
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 10px;
}

.status {
  border: none;
  border-radius: 0.25rem;
  font-weight: 700;
  padding: 2px 5px;
  color: #fff;
}

.closed {
  background-color: red;
}

.opened {
  background-color: #5eba00;
}

.img_container {
  position: relative;
  cursor: pointer;
}

.img_enlarged {
  position: absolute;
  top: -10%;
  left: 70%;
  display: none;
  z-index: 10;
}

.img_container:hover .img_enlarged {
  display: block;
}

.img_enlarged img {
  width: 200px;
  height: 200px;
  border-radius: 10%;
  object-fit: fill;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.category_list, .category_line {
  text-transform: capitalize;
  font-size: 20px;
  cursor: pointer;
  border-bottom: 2px solid #e5e7eb;
  width: 100%;
  padding: 5px 0 10px;
}

.category_line {
  display: none;
}

.arrow {
  float: right;
}

.child_category {
  text-transform: capitalize;
  font-size: 20px;
  cursor: pointer;
  border-bottom: 1px solid #e5e7eb;
  padding: 5px;
  margin-left: 5px;
}

.category {
  display: flex;
  justify-content: space-between;
}

.category_list:hover,
.child_category:hover {
  color: #00778b;
}

.arrowdown,
.arrowup {
  cursor: pointer;
}

.page__number {
  font-size: 20px;
  border: 1px solid #8d929d;
  padding: 5px 13px;
  margin: 0 1%;
  border-radius: 5px;
}

.buttonsmenu {
  display: flex;
  justify-content: center;
}

.all_filter_sticky {
  position: sticky;
  z-index: 1;
  background-color: #fff;
  top: 7%;
  max-height: 75vh;
  overflow-x: hidden;
}

.category__list .active {
  color: #00778b;
  font-weight: bold;
  transform: none;
}

.category__list {
  width: 100%;
  margin-left: 10%;
  line-height: 2rem;
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.02);
}

.card-price {
  color: #525a65;
  font-weight: bold;
  font-size: 14px;
}

.card-img-top:hover,
.card-text:hover {
  cursor: pointer;
}

.product-image {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.product-image_table {
  width: 3rem;
  height: 3rem;
}

.card-text {
  color: #8d929d;
  text-decoration: none;
  font-size: 16px;
}

.card-text a {
  color: #8d929d;
}

.card-text strong {
  color: #4d626d;
}

/* add to cart */

.outofstock {
  width: 100%;
  text-align: center;
  background-color: #f3f4f6;
  border-radius: 5px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.addtocard {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cartadd {
  width: 80%;
  text-align: center;
  background-color: #f3f4f6;
  border-radius: 5px 0 0 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.sub-btn {
  width: 20%;
  text-align: center;
  background-color: #e5e7eb;
  border-radius: 5px 0 0 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.add-sym {
  width: 20%;
  text-align: center;
  background-color: #e5e7eb;
  border-radius: 0 5px 5px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.quantity-btn {
  width: 80%;
  text-align: center;
  background-color: #e5e7eb;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addtocard:hover {
  cursor: pointer;
  color: #fff;
}

.addtocard:hover .cartadd {
  background-color: #20abc1;
}

.aaddtocarddd:hover .sub-btn {
  background-color: #00778b;
}

.addtocard:hover .quantity-btn {
  background-color: #20abc1;
}

.addtocard:hover .add-sym {
  background-color: #00778b;
}

.addtocard.active .quantity-btn,
.addtocard.active .add-sym,
.addtocard.active .sub-btn {
  background-color: #20abc1;
  color: #fff;
}

.addtocard.active .add-sym:hover,
.addtocard.active .sub-btn:hover {
  background-color: #00778b;
}

.message {
  margin: 1.5rem auto 1rem;
  padding: 10px 0px;
  text-align: center;
  /* width: 80%; */
}

/* add to cart */

.popup {
  position: fixed;
  top: 0;
  right: -100%;
  width: 200px;
  height: 100vh;
  transition: right 0.3s ease;
  z-index: 100;
  overflow-y: scroll;
}

.popup.show {
  right: 0;
  width: 33%;
}

.show {
  display: block;
}

.cart-details {
  position: absolute;
  top: 0;
  color: black;
  width: 100%;
  z-index: -1;
}

.vendor_nav {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toggle_btn {
  margin: 0 30px;
  cursor: pointer;
}

.toggle_btn img {
  height: 2rem;
  width: 2rem;
}

.vendor_nav {
  position: sticky;
  top: 0;
  z-index: 600;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.section_nav span {
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 20px;
}

.section_nav span:hover {
  color: #20abc1;
}

.section_nav .active {
  color: #00778b;
  font-weight: bold;
}

.about_section {
  width: 90%;
  margin: 20px auto;
  text-align: justify;
  font-size: 18px;
}

.section_title {
  text-align: center;
  padding-top: 20px;
}

.search_product {
  width: 100%;
  position: relative;
  display: inline-block;
}

.search_product input {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.suggestions li {
  text-transform: capitalize;
}

.suggestions {
  max-width: 40rem !important;
  background: #fafafa !important;
}

.gallery-section {
  padding: 20px 50px;
}

@media screen and (max-width: 900px) {
  .vendorInfo {
    flex-direction: column;
  }

  .contactInfo,
  .openingHours {
    width: 100%;
  }

  .vendorSubtitle {
    text-align: center;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .search_product input {
    max-width: 99%;
    margin-top: 30px;
  }
  .all_filter_sticky {
    max-height: 30vh;
  }

  .category__list {
    margin-left: 7%;
  }

  .category_line {
    display: block;
  }

  .gallery-section {
    padding: 10px;
  }
  .section_nav span {
    font-size: 18px;
  }

  .all_filter_sticky::-webkit-scrollbar {
    width: 15px;
  }
  
  .all_filter_sticky::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .all_filter_sticky::-webkit-scrollbar-thumb {
    background: #888; 
  }

}

@media screen and (max-width: 767px) {
  /* Customize vertical scrollbar thumb */
  .all_filter_sticky::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Radius of the scrollbar thumb */
  }

  .all_filter_sticky .category__list::-webkit-scrollbar-thumb:hover {
    background: #555; /* Hover color of the scrollbar thumb */
  }
}

