@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap");

#main_nav {
  height: auto;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  background: #fafafa;
}

.nav_content {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; 
}
.logo {
  width:40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: sans-serif;
  letter-spacing: -2px;
  flex: 1;
}
.logo h1 {
  color: #171f6b;
}
.logo span {
  color: #01c48f;
}

.nav-others {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.8%;
  font-weight: bolder;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 24, 24);
}
.nav_others_all {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.nav-others-content {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.nav-others-content .active div{
  color: #007bff;
  transform: scale(1.08);
  font-weight: 900;
}
.nav-others-button {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
}
.hamburger-icon {
  display: flex; /* Change display to flex to make the icon visible */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 20px;
  cursor: pointer;
  flex: 0;
}
.line-1,.line-2,.line-3{
  transition: 0.5s;
}
.active .line-1{
transform: rotate(45deg) translateX(5px);
}
.active .line-3{
  opacity: 0;
}
.active .line-2{
  transform: rotate(-45deg) translateX(5px);
}

.line {
  width: 30px;
  height: 2px;
  background-color: black;
  margin: 6px 0;
}
/* Hidden menu styles */
.nav_others_all_mobile {
  display: none;
  opacity: 1; /* Set the initial opacity to 0 to make it invisible */
}
.nav_hamburger_design{
  display: flex;
  width: 100%;
}
.show-menu .nav_others_all_mobile {
  display: flex;
  flex-direction: column;
  opacity: 1; /* Set the opacity to 1 to make it visible */
}

.show-menu .nav_others_all {
  display: none;
}

.server-down-message {
  background-color: #ffcccc;
  color: #ff0000;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid #ff0000;
}


@media all and (max-width: 850px) {
  .logo {
    width:30%;
  }
  .nav-others{
    width: 70%;
  }
}

/* Media query for the hamburger menu */
@media all and (max-width: 750px) {
  .nav_content {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .nav-others {
    width: 100%;
  }

  .nav_others_all {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .nav-others-button > button {
    padding: 4% 2%;
    margin: 0;
  }

  .hamburger-icon {
    display: block;
    margin: 10px;
  }

  .nav_others_all {
    display: none;
  }

  .nav_others_all_mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

  }

  .nav_others_all_mobile a {
    padding: 10px;
    color: black;
    text-decoration: none;
    
  }

  .nav_hamburger_design {
    position: sticky;
    top: 0;
  }
}

@media all and (max-width: 500px) {
  .button_navigation_1 {  
    font-size: 0.8rem;
  }
  .button_navigation_2 {
    font-size: 0.8rem;
  }
}
