#main_home {
  width: 100%;
  height: 100vh;
}
.home {
  height: 60%;
  top: 13%;
  width: 100%;
  background-image: url("../../assets/grocery_bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.home_content {
  text-align: center;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home_content h1 {
  font-size: 3rem;
  color: #1f2937;
  margin: 2% 0%;
}
.product_show_1 {
  max-width: 100%;
}

.table_product {
  width: 90%;
}

.home_content p {
  margin: 4% 0%;
  font-size: 1.2rem;
}
.home_search {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_category {
  width: 40%;
  position: relative;
}
.search_category input[type="search"]:focus + .filtered_results {
  display: block;
}
.search_category input[type="search"]:focus {
  border: 1px solid #009f7f;
}
.search_city input[type="search"]:focus {
  border: 1px solid #009f7f;
}
.search_category input[type="search"] {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.search_city {
  width: 40%;
}
.search_city input[type="search"] {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  font-size: 0.8em;
  letter-spacing: 0.5px;
  margin-left: 1%;
}
.search_button {
  background-color: #009f7f;
  padding: 14px;
  color: #fff;
  border-radius: 0px 8px 8px 0px;
  display: flex;
}
.product-show {
  width: 99%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  height: auto;
}

table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}

.table td,
.table th {
  vertical-align: middle;
}

/* CSS */
.tr_tables .img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.img-container {
  position: relative;
}

.img-enlarged {
  position: absolute;
  top: -10%;
  left: 100%;
  display: none;
  z-index: 1;
  background: #fff;
}

.img-container:hover .img-enlarged {
  display: block;
}

.img-enlarged img {
  width: 200px;
  height: 200px;
  border-radius: 10%;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.table-container {
  overflow: auto;
}

.table .authorName, .table .lastupdated, .table .productName {
  max-width: 150px; 
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-header {
  padding: 10px;
}
.table_for_responsive {
  display: none;
}

.first-column {
  padding-left: 20px;
}
.vendor_link {
  color: #333;
}
.suggestions,
.suggestions_city {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white !important;
  padding: 0;
  margin-top: 2px;
  list-style: none;
  text-align: left;
  max-height: 200px; 
  overflow-y: auto;
  border-radius: 10px;
}

.loading-animation {
  padding: 8px 10px; /* Padding around the loading text */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* top: 100%; */
  left: 0;
  width: 100%;
  background-color: white;
  padding: 0;
  margin-top: 2%;
  list-style: none;
  text-align: left;
}

.verified {
  background-color: #1d98f0;
  border-radius: 50%;
  color: #fff;
}

.search_city {
  position: relative;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
  /* text-transform: capitalize; */
}

.suggestions li:hover {
  background-color: #009f7f;
  color: #fff;
}
.navigation_button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  margin-top: 5%;
}
.page_nav {
  width: 100%;
  display: flex;
  justify-content: center;
}
li.selected {
  background-color: #eaf3ff;
}

.active-enquiry, .active-cart {
  overflow-y: hidden;
}

.store_Status span{
  cursor: auto !important;
}

@media all and (max-width: 750px) {
  #main_home {
    height: auto;
  }
  .home {
    height: auto;
    top: 20%;
  }

  .table_product {
    display: none;
  }
}
.button_for_responsive {
  display: flex;
  gap: 3%;
}
@media all and (max-width: 750px) {
  .table_for_responsive {
    display: block;
    width: 100%;
  }
  .table_for_bigscreen {
    display: none;
  }
  .img-container:hover .img-enlarged {
    display: none;
  }
}
@media all and (max-width: 600px) {
  .home_search {
    width: 100%;
    gap: 5%;
  }
  .home {
    background-image: none;
  }

  .search_city,
  .search_category {
    width: 100%;
    margin: 3% 0 0 0;
  }
  .home_content {
    width: 90%;
  }
  .home_content h1 {
    font-size: 1.5rem;
  }
  .home_content p {
    font-size: 0.8rem;
  }
  .search_button {
    display: none;
  }
}