.section_padding {
  padding: 0rem 2rem;
}

.footer {
  background-color: #fafafa;
}

.pf_footer {
  display: flex;
  flex-direction: column;
}

.pf_footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.pf_footer-links .about-us {
  font-size: 15px;
  line-height: 18px;
  padding: 0 15px;
  text-align: justify;
  margin-top: 0;
}

.pf_footer-links-div {
  width: 250px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.social_media {
  display: flex;
  flex-direction: row;
}

.pf_footer-links-div h4 {
  font-size: 24px;
  line-height: 17px;
  margin: 1.3rem 0;
}

.pf_footer-links-div p {
  font-size: 14px;
  line-height: 15px;
  margin: 0.5rem 0 0.3rem 0;
}

.pf_footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.pf_footer-below-links {
  display: flex;
  flex-direction: row;
}

.pf_footer-below-links p {
  font-size: 16px;
  line-height: 15px;
  margin-left: 2rem;
  font-weight: 600;
}

.social_media p {
  font-size: 22px;
}

.pf_footer-copyright {
  font-size: 13px;
  line-height: 15px;
}

.timimgs_list {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.links span {
  cursor: pointer;
}

@media screen and (max-width: 627px) {
  .pf_footer-links-div {
    width: 300px;
  }

  .pf_footer-links .about-us {
    padding: 0 10px;
  }

  .pf_footer-links {
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .pf_footer-links {
    font-size: 14px;
    line-height: 20px;
  }
  .pf_footer-below {
    flex-direction: column;
    justify-content: left;
  }
  .pf_footer-below-links {
    gap: 2rem;
  }

  .pf_footer-below-links p {
    margin-left: 0;
    margin-top: 1rem;
  }
}
